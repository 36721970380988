import { encryptData } from '@doltech/core/lib/encrypt-page-data/encryptData';
import {
  getCommonDataPreRendering,
  getHomePageUrlInfo,
} from '@doltech/ui/lib/figma/Layout/Landing/api/pages-landing.m2m';
import { retainOnlyUrlOfStaticPageLink } from '@doltech/ui/lib/hocs/retainOnlyUrlOfStaticPageLink';
import { CommonSlugPage } from '@doltech/ui/lib/shared/components/SlugPage/CommonSlugPage';
import * as React from 'react';
import { filterDataByPageType } from '../routers/filterDataByPageType';
import { getDataPreRendering } from '../routers/route';
import { PageTemplate } from '../routers/route.template';

import { replaceLandingCanonicalUrl } from '@doltech/ui/lib/shared/components/SlugPage/changeLandingCanonicalUrl';

const HomePage = (props) => {
  const { slug, isStructuralSEO, urlInfo } = props;
  return (
    <CommonSlugPage
      {...props}
      parseLocalSeoMedataOption={{
        replace: replaceLandingCanonicalUrl({ slug, isStructuralSEO }),
      }}
      hardCodeHeadTags={`
        <>
          <meta property="og:locale" content="vi_VN" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:alt" content="${urlInfo.dol?.title}" />
          <meta name="twitter:site" content="@EnglishDol" />
          <meta name='dmca-site-verification' content='RmRSSnYwUEEwR2FoUzRZT2RBVnNOSzFvL3M3MFM3dmZCK0gwdUhvS3BuQT01' />
        </>
      `}
    >
      {({ allProps }) => <PageTemplate pageType={urlInfo?.type} pageProps={allProps} />}
    </CommonSlugPage>
  );
};

export default HomePage;

export const getServerSideProps = async ({ res }) => {
  res.setHeader('Cache-Control', 'public, s-maxage=3600, stale-while-revalidate=1800');
  const homePageData = await getHomePageUrlInfo();
  if (homePageData?.url && homePageData?.url !== '/') {
    return {
      redirect: {
        destination: homePageData.url,
        statusCode: 302,
      },
    };
  }
  const data = filterDataByPageType(await getDataPreRendering(homePageData), homePageData?.type);
  const commonData = await retainOnlyUrlOfStaticPageLink(await getCommonDataPreRendering());

  return {
    props: encryptData({
      urlInfo: homePageData,
      structuralSeo: homePageData?.metadata || '',
      schemaScriptString: homePageData?.google?.schemaHtml || '',
      data: {
        ...commonData,
        ...data,
      },
    }),
  };
};
